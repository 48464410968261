@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slider-title {
  -webkit-text-stroke: 3px #00005E;
  
}

.slider-subtitle {
  -webkit-text-stroke: 1.5px #00005E;
}

.solutions-shadow {
  filter: drop-shadow(0px 3px 16px rgba(0, 0, 0, 0.25));
}

.benefits-shadow {
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
}
